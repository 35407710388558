/** @jsx jsx */
import {jsx} from 'theme-ui'
import Layout from 'components/Layout'
import {graphql, PageProps} from 'gatsby'
import {FaqQuery} from '../graphqlTypes'
import Collapse from 'antd/es/collapse'
import collapsePlus from 'images/collapse-plus.svg'
import collapseMinus from 'images/collapse-minus.svg'
import 'antd/es/collapse/style/index.css'
import SEO from 'components/SEO'
import {PageContext} from 'gatsbyTypes'
import useAutoSelectLanguage from 'hooks/useAutoSelectLanguage'
import {useTranslation} from 'react-i18next'

const {Panel} = Collapse

interface Props extends PageProps<FaqQuery, PageContext> {}

const FaqPage: React.FC<Props> = ({data, pageContext}) => {
  useAutoSelectLanguage(pageContext)
  const {t} = useTranslation()
  const items = data.allMarkdownRemark.nodes
  return (
    <Layout
      sx={{
        maxWidth: 1440 - 204 * 2,
        mx: 'auto',
        px: [20, , 0],
        pt: [80, , 150],
        pb: [80, , 100],
      }}
    >
      <SEO
        title={t('Frequently asked questions')}
        description={t(
          'Here are the recurring questions and important topics to know about Ummaty',
        )}
      />
      <div
        sx={{
          width: ['100%', 550, 852],
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
        }}
      >
        <h1
          sx={{
            my: 0,
          }}
        >
          {t('Frequently asked questions')}
        </h1>
        <Collapse
          bordered={false}
          expandIconPosition="right"
          expandIcon={({isActive}) => (
            <img src={isActive ? collapseMinus : collapsePlus} />
          )}
          sx={{
            '.ant-collapse-header': {
              fontSize: 22,
              lineHeight: '26px !important',
            },
            '.ant-collapse-item': {
              marginTop: 60,
            },
            '&.ant-collapse-borderless': {
              backgroundColor: 'transparent',
            },
          }}
        >
          {items.map(({frontmatter: {title}, html}, index) => {
            return (
              <Panel key={index} header={`${index + 1}. ${title}`}>
                <div
                  dangerouslySetInnerHTML={{__html: html}}
                  sx={{
                    fontSize: [16, , 18],
                  }}
                ></div>
              </Panel>
            )
          })}
        </Collapse>
      </div>
    </Layout>
  )
}

export default FaqPage

export const pageQuery = graphql`
  query Faq($langKey: String!) {
    allMarkdownRemark(
      filter: {
        fileAbsolutePath: {regex: "//faq//"}
        frontmatter: {language: {eq: $langKey}}
      }

      sort: {order: ASC, fields: fileAbsolutePath}
    ) {
      nodes {
        id
        frontmatter {
          title
        }
        html
      }
    }
  }
`
